export default [
  {
    id: 1,
    title: "装修选材APP应用",
    category: "行业应用",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    subtitle: "一站式整体软硬装选材服务平台",
    desc:
      "该平台是“互联网+”一站式整体软硬装选材服务平台，针对传 统软硬装家具产业颠覆式的创新，F2C模式，省去传统的中间环节，一站式解决 中高端设计师整体选材难题。",
    bgUrl: require("@/assets/images/cpzs_1.jpg"),
    usedList: [
      { name: "在线选材", imgUrl: require("@/assets/images/cpzs1-1.jpg") },
      { name: "设计灵感", imgUrl: require("@/assets/images/cpzs1-2.jpg") },
      { name: "供应商直达", imgUrl: require("@/assets/images/cpzs1-3.jpg") }
    ],
    advantage: [
      { name: "行业定制", imgUrl: require("@/assets/images/5.png") },
      { name: "资源管理", imgUrl: require("@/assets/images/6.png") },
      { name: "产品展示", imgUrl: require("@/assets/images/7.png") },
      { name: "用户体验", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "在线选材", desc: "" },
      { name: "设计灵感", desc: "" },
      { name: "供应商直达", desc: "" }
    ]
  },
  {
    id: 2,
    category: "行业应用",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "中小学在线教育题库系统",
    subtitle: "服务中小学在线教育、互联网教育直播点播整体解决方案",
    desc:
      "针对全国各类中小学校、培训机构统一采购在线题库资源而推出的集体付费包年服务。帮助中小学校及培训机构低成本、快速打造自己的校本题库应用平台，是学校提高教研水平的好帮手。它针对性地解决了学校在建设校本题库过程中遇到的诸多问题。",
    bgUrl: require("@/assets/images/cpzs_2.jpg"),
    usedList: [
      { name: "数字校园", imgUrl: require("@/assets/images/cpzs2-1.jpg") },
      { name: "在线题库", imgUrl: require("@/assets/images/cpzs2-2.jpg") },
      { name: "自动组题", imgUrl: require("@/assets/images/cpzs2-3.jpg") }
    ],
    advantage: [
      { name: "智慧校园", imgUrl: require("@/assets/images/5.png") },
      { name: "海量题库", imgUrl: require("@/assets/images/6.png") },
      { name: "场景定制", imgUrl: require("@/assets/images/7.png") },
      { name: "性能服务", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "数字校园", desc: "" },
      { name: "在线题库", desc: "" },
      { name: "自动组题", desc: "" }
    ]
  },
  {
    id: 3,
    category: "行业应用",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "高端酒店电子节目指南系统",
    subtitle:
      "电子节目指南（EPG）运行于Android盒子/Linux一体机，为高端酒店提供全套酒店多媒体服务方案",
    desc:
      "电子节目指南（EPG）运行于Android盒子/Linux一体机，为高端酒店提供电视直播、视频点播、酒店图文介绍、旅游信息、航班、天气、汇率、信箱等功能全套酒店多媒体服务方案，华丽的视觉效果，流畅的操作体验。",
    bgUrl: require("@/assets/images/cpzs_3.jpg"),
    usedList: [
      { name: "酒店管理", imgUrl: require("@/assets/images/cpzs3-1.jpg") },
      { name: "多媒体服务", imgUrl: require("@/assets/images/cpzs3-2.jpg") },
      { name: "行程攻略", imgUrl: require("@/assets/images/cpzs3-3.jpg") }
    ],
    advantage: [
      { name: "行业服务", imgUrl: require("@/assets/images/5.png") },
      { name: "多场景", imgUrl: require("@/assets/images/6.png") },
      { name: "资源统一管理", imgUrl: require("@/assets/images/7.png") },
      { name: "高端时效", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "酒店管理", desc: "" },
      { name: "多媒体服务", desc: "" },
      { name: "行程攻略", desc: "" }
    ]
  },
  {
    id: 4,
    category: "行业应用",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "益慧仓小程序在线商城系统",
    subtitle: "针对传统批发行业提供全套在线服务，用于对商品批发零售管理的系统",
    desc:
      "该项目以微信小程序为基础，面向传统批发行业提供在线选货、订货、发货、支付整体服务，大大改善了传统批发行业的老旧路程和操作困难问题，并且为批发商家提供全方位的数据分析服务，更高效评估进货出货的体量规划。",
    bgUrl: require("@/assets/images/cpzs_4.jpg"),
    usedList: [
      { name: "在线批发", imgUrl: require("@/assets/images/cpzs4-1.jpg") },
      { name: "订单服务", imgUrl: require("@/assets/images/cpzs4-2.jpg") },
      { name: "数据分析", imgUrl: require("@/assets/images/cpzs4-3.jpg") }
    ],
    advantage: [
      { name: "行业电商", imgUrl: require("@/assets/images/5.png") },
      { name: "数据服务", imgUrl: require("@/assets/images/6.png") },
      { name: "订单流程", imgUrl: require("@/assets/images/7.png") },
      { name: "多端支持", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "在线批发", desc: "" },
      { name: "订单服务", desc: "" },
      { name: "数据分析", desc: "" }
    ]
  },
  {
    id: 5,
    category: "容器云",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "服务器综合监控方案",
    subtitle:
      "基于WEB界面的提供分布式系统监视以及网络监视功能的企业级的整体解决方案",
    desc:
      "该项目是一个基于WEB界面的提供分布式系统监视以及网络监视功能的企业级的整体解决方案，能监视各种网络参数，保证服务器系统的安全运营；并提供灵活的通知机制以让系统管理员快速定位/解决存在的各种问题，针对虚拟机服务器/硬件服务器出现故障异常问题，实现实时短信/邮件/微信通知。兼容现有大部分系统平台，可以运行在Linux，Solaris，HP-UX，AIX，Free BSD，Open BSD，OS X，Windows等平台上。",
    bgUrl: require("@/assets/images/cpzs_5.jpg"),
    usedList: [
      { name: "接入标准化", imgUrl: require("@/assets/images/cpzs5-1.jpg") },
      { name: "监控规范化", imgUrl: require("@/assets/images/cpzs5-2.jpg") },
      { name: "管理智能化", imgUrl: require("@/assets/images/cpzs5-3.jpg") },
      { name: "预警高效化", imgUrl: require("@/assets/images/cpzs5-4.jpg") }
    ],
    advantage: [
      { name: "高兼容性", imgUrl: require("@/assets/images/5.png") },
      { name: "实时监控", imgUrl: require("@/assets/images/6.png") },
      { name: "智能管理", imgUrl: require("@/assets/images/7.png") },
      { name: "大数据报表", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "接入标准化", desc: "" },
      { name: "监控规范化", desc: "" },
      { name: "管理智能化", desc: "" },
      { name: "预警高效化", desc: "" }
    ]
  },
  {
    id: 6,
    category: "数据趋势",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "物联网空调设备数据采集分析系统",
    subtitle:
      "基于空调设备硬件模块+数据中转采集模块，对接专网数据采集API接口，通过可视化系统服务升级",
    desc:
      "该项目是基于空调设备硬件模块+数据中转采集模块，对接专网数据采集API接口服务，接入内部数据处理系统，数据处理系统融合数据过滤、数据清洗、数据存储等功能，再通过可视化系统服务实现可视化的报表定义、流程关系的定义、设备和用户信息管理、日常设备运维数据综合查询统计等功能模块。",
    bgUrl: require("@/assets/images/cpzs_6.jpg"),
    usedList: [
      { name: "设备兼容", imgUrl: require("@/assets/images/cpzs6-1.jpg") },
      { name: "统一采集", imgUrl: require("@/assets/images/cpzs6-2.jpg") },
      { name: "实时监控", imgUrl: require("@/assets/images/cpzs6-3.jpg") },
      { name: "高效运维", imgUrl: require("@/assets/images/cpzs6-4.jpg") }
    ],
    advantage: [
      { name: "可拓展", imgUrl: require("@/assets/images/5.png") },
      { name: "高并发高可用", imgUrl: require("@/assets/images/6.png") },
      { name: "海量数据支持", imgUrl: require("@/assets/images/7.png") },
      { name: "实时监控", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "设备兼容", desc: "" },
      { name: "统一采集", desc: "" },
      { name: "实时监控", desc: "" },
      { name: "高效运维", desc: "" }
    ]
  },
  {
    id: 7,
    category: "数据趋势",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "可视化数据图表分析系统",
    subtitle: "针对精细化数据可视化图表分析的整体解决方案",
    desc:
      "该系统，由我司与多家大数据处理方案公司，经过1年多的创新研发，推出的一个针对精细化数据可视化图表分析的整体解决方案，为企业级客户的数据分析人员提供快速的数据统计、数据探索、报表制作等功能。为用户提供专业化可视化图表展示，为各级生产实践提供快速高效的数据统计支撑。",
    bgUrl: require("@/assets/images/cpzs_7.jpg"),
    usedList: [
      { name: "数据可视化", imgUrl: require("@/assets/images/cpzs7-1.jpg") },
      { name: "业务报表", imgUrl: require("@/assets/images/cpzs7-2.jpg") },
      { name: "智能统计", imgUrl: require("@/assets/images/cpzs7-3.jpg") },
      { name: "标准输出", imgUrl: require("@/assets/images/cpzs7-4.png") }
    ],
    advantage: [
      { name: "前沿数据展示", imgUrl: require("@/assets/images/5.png") },
      { name: "智能统计", imgUrl: require("@/assets/images/6.png") },
      { name: "统一报表", imgUrl: require("@/assets/images/7.png") },
      { name: "大数据引擎", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "数据可视化", desc: "" },
      { name: "业务报表", desc: "" },
      { name: "智能统计", desc: "" },
      { name: "标准输出", desc: "" }
    ]
  },
  {
    id: 8,
    category: "智能物联",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "物联网设备综合运营管理平台",
    subtitle: "用AI科技搭建的一整套智能云服务平台，帮助使用者管理监控设备",
    desc:
      "智能设备制造公司生产制造设备，随着设备品种的不断扩展，和设备安装 量的增长，如何集中管控设备成了一个急迫的话题。随着智能制造的兴起，客户对设备状 态的把握也要求越来越高，也需要设备制造商提供更好的平台给客户管理监控设备。智能 设备制造商如何从设备制造商，转向智能制造运营商，也是面临的一个主要课题。随着人 力成本的不断增长，如何用AI科技来帮助使用者管理监控设备，需求越来越大。因此客户 方紧迫需要搭建一整套智能云服务平台，来实现设备接入管理、监控、数据运营等功能。",
    bgUrl: require("@/assets/images/cpzs_8.jpg"),
    usedList: [
      { name: "智能芯片", imgUrl: require("@/assets/images/cpzs8-1.jpg") },
      {
        name: "精准数据采集",
        imgUrl: require("@/assets/images/cpzs8-2.jpg")
      },
      { name: "人工智能", imgUrl: require("@/assets/images/cpzs8-3.jpg") },
      { name: "云端管理", imgUrl: require("@/assets/images/cpzs8-4.jpg") }
    ],
    advantage: [
      { name: "自研行业芯片", imgUrl: require("@/assets/images/5.png") },
      { name: "行业兼容", imgUrl: require("@/assets/images/6.png") },
      { name: "人工智能", imgUrl: require("@/assets/images/7.png") },
      { name: "海量数据引擎", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "智能芯片", desc: "" },
      { name: "精准数据采集", desc: "" },
      { name: "人工智能", desc: "" },
      { name: "云端管理", desc: "" }
    ]
  },
  {
    id: 9,
    category: "行业应用",
    categoryUrl: require("@/assets/images/detail-bg.jpg"),
    title: "展会运营管理系统",
    subtitle:
      "智慧云端服务系统，使得行业展会、行业大会更加便捷高效、流程清晰，举办方、参会方对于各项繁琐事项、资讯通知、结果展示，都能一目了然、运筹帷幄。",
    desc:
      "智慧云端服务系统，由各个微服务组成，针对行业展会和大会，提供一系列的功能需求，满足展会的资讯宣传、信息填报、会议报名、展会作品、大会流程管理、资源管理、历史回顾等等需求。通过技术创新、线上无纸化流程、大数据融合等方式，让展会和大会举办起来，操作更加便捷、流程更加清晰、数据更加精准。",
    bgUrl: require("@/assets/images/cpzs_9.jpg"),
    usedList: [
      { name: "会展服务", imgUrl: require("@/assets/images/cpzs9-1.jpg") },
      { name: "流程管理", imgUrl: require("@/assets/images/cpzs9-2.jpg") },
      { name: "数据采集", imgUrl: require("@/assets/images/cpzs9-3.jpg") }
    ],
    advantage: [
      { name: " 行业智慧云端", imgUrl: require("@/assets/images/5.png") },
      { name: " 场景支持", imgUrl: require("@/assets/images/6.png") },
      { name: " 流程学习", imgUrl: require("@/assets/images/7.png") },
      { name: " 业务大数据", imgUrl: require("@/assets/images/8.png") }
    ],
    function: [
      { name: "会展服务", desc: "" },
      { name: "流程管理", desc: "" },
      { name: "数据采集", desc: "" }
    ]
  }
];
